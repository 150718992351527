import Img from "gatsby-image";
import { Link } from "gatsby";
import React from "react";
import theme from "../styles/theme";

const Partners = ({ dataTitle, dataPartners }) => {
  return (
    <section
      css={`
        padding-top: 100px;
        padding-bottom: 180px;
        flex-direction: column;
        ${theme.tablet} {
          padding-bottom: 0;
        }
      `}
    >
      <h2>{dataTitle || "Partners"}</h2>
      <div
        css={`
          display: grid;
          justify-content: center;

          grid-template-columns: repeat(4, 1fr);
          border-top: 1px solid ${theme.pink};
          border-left: 1px solid ${theme.pink};
          ${theme.max1300} {
            grid-template-columns: repeat(3, 1fr);
          }
          ${theme.max600} {
            grid-template-columns: repeat(1, 1fr);
          }
          > div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 200px;
            border-bottom: 1px solid ${theme.pink};
            border-right: 1px solid ${theme.pink};
            .img {
              position: relative;
              display: flex;
              width: 60%;
              max-height: 100px;
            }
            strong {
              font-weight: 900;
              font-size: 24px;
            }
            p {
              font-size: 18px;
              line-height: 150%;
            }
            ${theme.max900} {
              > div h3 {
                font-size: 28px;
              }
            }
          }
          .next {
            background: ${theme.pink};
            color: #fff;
            h3 {
              font-size: 26px;
              margin-top: 15px;
              font-weight: 900;
            }
          }
        `}
      >
        {dataPartners.map(item => {
          return (
            <div>
              <Img
                className="img"
                fluid={item.img.fluid}
                alt={item.img.alt}
                imgStyle={{
                  objectFit: "contain",
                }}
              />
            </div>
          );
        })}
        <div className="next">
          <h3>Are you next?</h3>
          <a href="mailto:sales@yonoton.com" className="button whiteOutlines">
            sales@yonoton.com
          </a>
        </div>
      </div>
    </section>
  );
};

export default Partners;
