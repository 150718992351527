import { graphql } from "gatsby";
import Img from "gatsby-image";
import scrollTo from "gatsby-plugin-smoothscroll";
import React, { useRef, useEffect } from "react";
import { StructuredText } from "react-datocms";
import Allinone from "../components/Allinone";
import Features from "../components/Features";
import Highlight from "../components/Highlight";
import Layout from "../components/layout";
import Partners from "../components/Partners";
import PdfEmbed from "../components/PdfEmbed";
import SlickSlider from "../components/Slider";
import theme from "../styles/theme";
import Hls from "hls.js";

const IndustryPage = ({ data, location, pageContext }) => {
  const videoRef = useRef(null);
  const src = data.industry.heroVideo.video.streamingUrl;
  const poster = data.industry.heroVideo?.video.thumbnailUrl + "?time=0";

  useEffect(() => {
    let hls;
    if (videoRef.current) {
      const video = videoRef.current;

      if (video.canPlayType("application/vnd.apple.mpegurl")) {
        // Some browers (safari and ie edge) support HLS natively
        video.src = src;
      } else if (Hls.isSupported()) {
        // This will run in all other modern browsers
        hls = new Hls();
        hls.loadSource(src);
        hls.attachMedia(video);
      } else {
        console.error("This is a legacy browser that doesn't support MSE");
      }
    }

    return () => {
      if (hls) {
        hls.destroy();
      }
    };
  }, [videoRef]);

  const highlightData = {
    referenceLogo: data.industry.referenceLogo,
    reference: data.industry.reference,
    referenceImage: data.industry.referenceImage,
    backgroundImage: data.industry.backgroundImage,
    backgroundLayer: data.industry.backgroundLayer,
  };
  const allinoneData = {
    title: data.global.allInOneTitle,
    excerpt: data.global.allInOneExcerpt,
    cloudIcon: data.global.cloudIcon,
    products: data.global.products,
  };
  const featuresData = {
    features: data.industry.features,
    featuresItems: data.industry.featuresItems,
  };

  const pdfData = {
    title: data.industry.successStoryTitle,
    pdf: data.industry.pdf?.url,
  };

  return (
    <Layout
      headerType="transparent"
      location={location}
      seo={data.industry.seoMetaTags}
      logo={data.global.logoWhite}
    >
      <main
        css={`
          margin: 0 auto;
          padding-bottom: 240px;
          width: 100%;
          max-width: 1600px;
          box-sizing: border-box;
          ${theme.tablet} {
            padding-bottom: 60px;
          }
        `}
      >
        <section
          css={`
            @media (min-width: 1600px) {
              margin-left: calc(-100vw / 2 + 1600px / 2);
              margin-right: calc(-100vw / 2 + 1600px / 2);
              .content {
                padding-left: calc(100vw / 2 - 1380px / 2) !important;
              }
            }
            @media (min-width: 1600px) {
              height: 800px;
              video {
                height: 800px;
              }
            }

            position: relative;
            padding-left: 0;
            padding-right: 0;
            height: 600px;
            svg {
              cursor: pointer;
            }
            video {
              object-fit: cover;
              overflow: hidden;
              width: 100%;
            }
            .content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              height: 100%;
              padding-left: 120px;
              max-width: 630px;
              position: absolute;
              top: 0;
              left: 0;
              color: #fff;
              h1 {
                margin-top: 100px;
                font-size: 72px;
                margin-bottom: 0;
                ${theme.laptop} {
                  font-size: 58px;
                  margin-top: 80px;
                }
                ${theme.tablet} {
                  margin-top: 10px;
                }
                ${theme.mobile} {
                  font-size: 38px;
                  margin-top: 10px;
                }
              }
              p {
                font-size: 24px;
                line-height: 120%;
                margin-bottom: 24px;
              }
            }
            ${theme.laptop} {
              .content {
                padding-left: 80px;
              }
            }
            ${theme.tablet} {
              .content {
                padding-left: 40px;
                padding-right: 20px;
                margin-left: auto;
                margin-right: auto;
                p {
                  font-size: 18px;
                }
              }
            }
            ${theme.mobile} {
              height: 600px;
              .content {
                padding-left: 20px;
              }
            }
          `}
        >
          <video
            width="100%"
            height="auto"
            autoPlay={true}
            controls={false}
            muted
            playsInline
            loop
            ref={videoRef}
            poster={poster}
          />
          <div className="content">
            <h1>{data.industry.title}</h1>
            <p>{data.industry.text}</p>
            <svg
              onClick={() => scrollTo("#mainStart")}
              width="63"
              height="63"
              viewBox="0 0 63 63"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g filter="url(#filter0_d)">
                <circle
                  cx="31.5"
                  cy="29.5"
                  r="27.0417"
                  transform="rotate(90 31.5 29.5)"
                  stroke="white"
                  stroke-width="4.91667"
                />
                <path
                  d="M44.9727 21.1236L33.3308 40.4802C32.3754 42.0686 30.0728 42.0686 29.1175 40.4802L17.4755 21.1236"
                  stroke="white"
                  stroke-width="4.91667"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <filter
                  id="filter0_d"
                  x="0.361111"
                  y="0"
                  width="62.2778"
                  height="62.2778"
                  filterUnits="userSpaceOnUse"
                  color-interpolation-filters="sRGB"
                >
                  <feFlood flood-opacity="0" result="BackgroundImageFix" />
                  <feColorMatrix
                    in="SourceAlpha"
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  />
                  <feOffset dy="1.63889" />
                  <feGaussianBlur stdDeviation="0.819444" />
                  <feColorMatrix
                    type="matrix"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                  />
                  <feBlend
                    mode="normal"
                    in2="BackgroundImageFix"
                    result="effect1_dropShadow"
                  />
                  <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_dropShadow"
                    result="shape"
                  />
                </filter>
              </defs>
            </svg>
          </div>
        </section>
        <section
          id="mainStart"
          css={`
            flex-wrap: wrap;
            a {
              color: #e00084;
              opacity: 0.7;
              font-weight: 700;
              transition: 0.2s;
              &:hover {
                opacity: 1;
              }
            }
            ${theme.mobile} {
              margin-bottom: 40px;
            }
            > div {
              display: flex;
              flex-direction: column;
              justify-content: center;
              flex: 1;
              min-width: 280px;
              padding-top: 80px;
              h2 {
                margin: 0;
              }
            }
            .left {
              p {
                font-size: 24px;
                line-height: 120%;
              }
            }
            .right .img {
              width: 100%;
            }
            ${theme.laptop} {
              .left p {
                font-size: 16px;
              }
            }
          `}
        >
          <div className="left">
            <StructuredText
              data={data.industry.lead}
              renderBlock={({ record }) => {
                switch (record.__typename) {
                  case "DatoCmsImage":
                    return (
                      <div>
                        <img src={record.img.url} alt={record.img.alt} />
                      </div>
                    );
                  default:
                    return null;
                }
              }}
            />
          </div>
          <div className="right">
            <Img
              className="img"
              fluid={data.industry.leadImage.fluid}
              alt={data.industry.leadImage.alt}
              imgStyle={{
                objectFit: "contain",
              }}
            />
          </div>
        </section>
        <section>
          <div
            css={`
              padding-top: 100px;
              display: grid;
              grid-gap: 20px;
              grid-template-columns: repeat(4, 1fr);
              ${theme.max600} {
                padding-top: 0px;
                grid-template-columns: repeat(1, 1fr);
              }
              .item {
                h3 {
                  font-weight: 900;
                  margin-bottom: 0;
                }
                p {
                  margin-top: 12px;
                }
                margin-top: 40px;
                img {
                  width: 80%;
                  max-width: 160px;
                }
              }
            `}
          >
            {data.industry.leadItems.map((item, index) => {
              let iconUrl = item.icon.url.split("?").shift();
              return (
                <div key={index} className="item">
                  <img src={iconUrl} alt={item.icon.alt} />

                  <h3>{item.title}</h3>
                  <p>{item.content}</p>
                </div>
              );
            })}
          </div>
        </section>
        <section
          css={`
            margin-top: 50px;
            margin-bottom: 0;
            flex-direction: column;
            overflow: hidden;
          `}
        >
          {pdfData.pdf && <PdfEmbed data={pdfData} />}
        </section>
        <section
          css={`
            padding-top: 70px;
            flex-direction: column;
            > div {
              display: flex;
            }
            > div:first-child {
              justify-content: space-between;
            }
            .industryIcon {
              width: 220px;
              ${theme.mobile} {
                display: none;
              }
            }
            .desktop {
              display: flex;
              ${theme.tablet} {
                display: none;
              }
              flex-direction: column;
              padding-top: 50px;
              .journeyImg {
                width: 100%;
              }
              .steps {
                display: flex;
                .content {
                  flex: 1;
                  padding: 10px;
                  font-size: 16px;
                  strong {
                    font-weight: 900;
                    font-size: 18px;
                  }
                }
              }
            }
            .tablet {
              padding-top: 50px;
              display: none;
              ${theme.tablet} {
                display: flex;
                padding-top: 20px;
              }
              .journeyImg {
                padding-top: 30px;
                width: 200px;
                height: fit-content;
                ${theme.mobile} {
                  width: 150px;
                }
                ${theme.max400} {
                  width: 140px;
                }
              }
              .imgBox {
                display: flex;
                justify-content: flex-start;
                flex-direction: column;
              }
              .steps {
                display: flex;
                flex-direction: column;
                .content {
                  flex: 1;
                  padding-top: 20px;
                  font-size: 16px;
                  max-width: 320px;
                  p {
                    padding: 0;
                    line-height: 120%;
                  }
                  strong {
                    font-weight: 900;
                    font-size: 18px;
                  }
                  ${theme.tablet} {
                    flex: 1;
                    padding-left: 20px;
                  }
                  ${theme.mobile} {
                    flex: 1;
                    padding-left: 4px;
                    padding-top: 0;
                    font-size: 15px;
                    strong {
                      font-size: 14px;
                    }
                  }
                  ${theme.max400} {
                    font-size: 13px;
                    strong {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          `}
        >
          <div>
            <h2>{data.industry.customerJourneyTitle}</h2>
            <img
              className="industryIcon"
              src={data.industry.industryIcon.url.split("?").shift()}
              alt={data.industry.industryIcon.alt}
            />
          </div>
          <div className="desktop">
            <img
              className="journeyImg"
              src={data.industry.customerJourneyHorizontal.url
                .split("?")
                .shift()}
              alt={data.industry.customerJourneyHorizontal.alt}
            />
            <div className="steps">
              {data.industry.steps.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="content"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                );
              })}
            </div>
          </div>
          <div className="tablet">
            <div className="imgBox">
              <img
                className="journeyImg"
                src={data.industry.customerJourneyVertical.url
                  .split("?")
                  .shift()}
                alt={data.industry.customerJourneyVertical.alt}
              />
            </div>
            <div className="steps">
              {data.industry.steps.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="content"
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
                );
              })}
            </div>
          </div>
        </section>
        <Allinone data={allinoneData} mt="120px" />
        <SlickSlider data={data.industry.images} />

        <Features data={featuresData} />
        <Partners
          dataPartners={data.global.partners}
          dataTitle={data.industry.partnersTitle}
        />
        <Highlight data={highlightData} />
      </main>
    </Layout>
  );
};

export default IndustryPage;

export const query = graphql`
  query IndustryQuery($slug: String!) {
    industry: datoCmsIndustry(slug: { eq: $slug }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      text
      heroVideo {
        video {
          streamingUrl
          thumbnailUrl
        }
      }
      lead {
        value
        blocks {
          ... on DatoCmsImage {
            __typename
            id: originalId
            img {
              url
              alt
            }
          }
        }
      }
      leadImage {
        fluid(maxWidth: 620) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      leadItems {
        title
        content
        icon {
          url
          alt
        }
      }
      successStoryTitle
      pdf {
        url
      }
      customerJourneyTitle
      industryIcon {
        url
        alt
      }
      customerJourneyHorizontal {
        url
        alt
      }
      customerJourneyVertical {
        url
        alt
      }
      steps {
        content
      }
      images {
        fluid(maxWidth: 1200) {
          ...GatsbyDatoCmsFluid
        }
      }
      features {
        value
      }
      featuresItems {
        title
        content
        icon {
          url
          alt
        }
      }
      partnersTitle
      referenceLogo {
        fluid(maxWidth: 500) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      reference
      referenceImage {
        fluid(maxWidth: 500) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      backgroundImage {
        fluid(maxWidth: 1400) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      backgroundLayer {
        red
        green
        blue
        alpha
      }
    }

    global: datoCmsGlobal {
      allInOneTitle
      allInOneExcerpt
      cloudIcon {
        url
        alt
      }
      products {
        ... on DatoCmsProductItem {
          title
          content
          linkToPage {
            slug
          }
          img {
            fluid(maxWidth: 512) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
      logoWhite {
        fluid(maxWidth: 700) {
          ...GatsbyDatoCmsFluid
        }
        alt
      }
      partners {
        ... on DatoCmsPartner {
          img {
            fluid(maxWidth: 312) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`;
