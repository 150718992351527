import { Link } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import theme from "../styles/theme";

const Allinone = ({ data, mt, mb }) => {
  return (
    <section
      css={`
        position: relative;
        flex-direction: column;
        margin-top: ${mt || `100px`};
        margin-bottom: ${mb || `100px`};
        a:visited {
          color: ${theme.pink};
        }
        h3 {
          color: ${theme.blue};
          font-size: 24px;
          font-weight: 900;
          margin-bottom: 0;
        }
        > div {
          display: flex;
        }
        .upleft {
          flex: 1;
          position: relative;
          ${theme.max900} {
            display: none;
          }
          .cloud {
            position: absolute;
            top: 0;
            left: 80px;
            width: 50%;
            min-width: 240px;
            max-width: 280px;
          }
          .firstImg {
            position: relative;
            margin-top: 260px;
            width: 340px;
            margin-left: auto;
            margin-right: 100px;
          }
          .secondImg {
            position: relative;
            margin-top: 20px;
            width: 330px;
            margin-left: auto;
            margin-right: 100px;
            z-index: 1;
          }
          svg.svgSnap {
            width: auto;
            position: absolute;
            bottom: -52px;
            right: 210px;
            height: 62px;
            ${theme.max1500} {
              height: 52px;
            }
            ${theme.max1100} {
              display: none;
            }
          }
          ${theme.max1400} {
            .cloud {
              left: 20px;
            }
            .firstImg {
              margin-top: 200px;
              width: 340px;
            }
          }
          @media (max-width: 1262px) {
          }
        }
        .upright {
          flex: 1;
          position: relative;
          h2 {
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            line-height: 120%;
          }
          h3.om-title {
            margin-top: 140px;
            ${theme.max1400} {
              margin-top: 100px;
            }
            ${theme.max1100} {
              margin-top: 80px;
            }
            ${theme.max900} {
              margin-top: 24px;
            }
          }
          .button {
            margin: 20px 0 10px;
          }
          ${theme.laptop} {
            p {
              font-size: 16px;
            }
          }
          .cloud {
            display: none;
            max-width: 180px;
            top: 0;
            left: 0;
            position: absolute;
            ${theme.mobile} {
              display: flex;
            }
          }
          .consoleMobile {
            display: none;
            height: 160px;
            margin-top: 80px;
            margin-left: 30px;
            img {
              width: 90% !important;
              object-fit: contain !important;
              ${theme.mobile} {
                object-position: left !important;
              }
            }
            ${theme.mobile} {
              display: flex;
              height: 220px;
              margin-left: -80px;
            }
          }
          ${theme.mobile} {
            padding-left: 20%;
            padding-top: 100px;
          }
          ${theme.max400} {
            padding-left: 20%;
            padding-top: 95px;
          }
        }

        .lineContainer {
          position: absolute;
          top: 30px;
          right: 210px;
          svg {
            width: 130px;
            height: 620px;
          }
          ${theme.max1400} {
            top: 0;
            svg {
              width: 110px;
              path {
                d: path("M3 3H130C147.121 3 161 16.8792 161 34V688") !important;
              }
            }
          }
          @media (max-width: 1262px) {
          }
        }

        .middle {
          margin-top: 55px;
          background: #fff;
          position: relative;
          svg {
            width: 83%;
            margin: 0 auto;
          }
        }
        ${theme.max1100} {
          .lineContainer,
          .middle {
            display: none;
          }
        }
        .bottom {
          display: grid;
          grid-gap: 20px;
          grid-template-columns: repeat(4, 1fr);
          position: relative;
          padding-top: 0;
          ${theme.max1100} {
            margin-top: 40px;
            grid-template-columns: repeat(3, 1fr);
          }
          ${theme.mobile} {
            grid-template-columns: repeat(1, 1fr);
            padding-top: 0;
          }
          .sLine {
            display: none;
            margin-left: 40px;
            ${theme.mobile} {
              display: block;
            }
            ${theme.max400} {
              margin-left: 25px;
            }
            position: relative;
          }
          .item {
            ${theme.max1100} {
              padding-top: 40px;
            }
            ${theme.mobile} {
              margin-top: 50px;
            }
          }
          .img {
            height: 160px;
            img {
              width: 90% !important;
              object-fit: contain !important;
              ${theme.mobile} {
                object-position: left !important;
              }
            }
          }
          .content {
            position: relative;
            background: #fff;
            padding-right: 20px;
            padding-top: 24px;
            padding-bottom: 13px;
            h3 {
              margin-top: 0;
            }
            p {
              font-size: 16px;
              line-height: 130%;
            }
            .button {
              margin: 20px 0 10px;
            }
            ${theme.mobile} {
              padding-left: 40px;
            }
          }
          .mobileLine2 {
            display: none;
            position: absolute;
            top: -1050px;
            left: 159px;
          }
          ${theme.mobile} {
            .mobileLine2 {
              display: block;
            }
          }
          ${theme.max400} {
            .mobileLine2 {
              position: absolute;
              top: -1050px;
              left: 144px;
            }
          }
        }
      `}
    >
      <div>
        <div className="upleft">
          <div className="leftContainer">
            <div className="lineContainer">
              <svg
                width="124"
                height="691"
                viewBox="0 0 164 691"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M3 3H130C147.121 3 161 16.8792 161 34V688"
                  stroke="#E00084"
                  stroke-width="6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-dasharray="0.1 15"
                />
              </svg>
            </div>
            <img
              className="cloud"
              src={data.cloudIcon.url.split("?").shift()}
              alt="Cloud icon"
            />
            <Img
              className="firstImg"
              fluid={data.products[0].img.fluid}
              alt={data.products[0].img.alt}
            />
            <Img
              className="secondImg"
              fluid={data.products[1].img.fluid}
              alt={data.products[1].img.alt}
            />
            <svg
              className="svgSnap"
              width="6"
              height="76"
              viewBox="0 0 6 76"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 3L3 73"
                stroke="#E00084"
                stroke-width="6"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-dasharray="0.1 15"
              />
            </svg>
          </div>
        </div>
        <div className="upright">
          <div
            className="mobileLineDiv"
            css={`
              position: absolute;
              top: 50px;
              left: 40px;
              overflow: hidden;
              background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' height='11' width='4'><ellipse cx='2' cy='2' rx='2' ry='2' fill='rgb(224, 0, 132)' /></svg>");
              background-repeat: repeat-y;
              background-position-x: 0;
              width: 6px;
              height: calc(100% - 10px);
              display: none;
              ${theme.mobile} {
                display: block;
              }
              ${theme.max400} {
                left: 25px;
              }
            `}
          />

          <img
            className="cloud"
            src={data.cloudIcon.url.split("?").shift()}
            alt={data.cloudIcon.alt}
          />
          <h2>{data.title}</h2>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: data.excerpt }}
          />
          <Link className="button" to={`/platform`}>
            Read more
          </Link>
          <Img
            className="consoleMobile"
            fluid={data.products[0].img.fluid}
            alt={data.products[0].img.alt}
          />
          <h3>{data.products[0].title}</h3>
          <p>{data.products[0].content}</p>
          <Link
            className="button"
            to={`/services/${data.products[0].linkToPage?.slug}`}
          >
            Read more
          </Link>
          <Img
            className="consoleMobile"
            fluid={data.products[1].img.fluid}
            alt={data.products[1].img.alt}
          />
          <h3 className="om-title">{data.products[1].title}</h3>
          <p>{data.products[1].content}</p>
          <Link
            className="button"
            to={`/services/${data.products[1].linkToPage?.slug}`}
          >
            Read more
          </Link>
        </div>
      </div>
      <div className="middle">
        <svg
          height="auto"
          viewBox="0 0 1292 160"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1289 157V34C1289 16.8792 1275.12 3 1258 3H34C16.8792 3 3 16.8792 3 34V157"
            stroke="#E00084"
            stroke-width="6"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="0.1 15"
          />
          <path
            d="M835.5 19.998V154.002"
            stroke="#E00084"
            stroke-width="6"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="0.1 15"
          />
          <path
            d="M411.5 17.998V154.002"
            stroke="#E00084"
            stroke-width="6"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="0.1 15"
          />
        </svg>
      </div>
      <div className="bottom">
        <svg
          className="sLine"
          width="123"
          height="51"
          viewBox="0 0 123 51"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.00017 3V3C3.00007 12.0717 10.3542 19.4259 19.4259 19.4259L95 19.4259C108.807 19.4259 120 30.6188 120 44.4259L120 48"
            stroke="#E00084"
            stroke-width="4.27187"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="0.07 10.68"
          />
        </svg>
        <svg
          className="mobileLine2"
          width="4"
          height="3000"
          viewBox="0 0 4 793"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 2V3500"
            stroke="#E00084"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-dasharray="0.07 10.68"
          />
        </svg>
        {data.products.slice(2).map((item, index) => {
          return (
            <div key={index} className="item">
              <Img className="img" fluid={item.img.fluid} alt={item.img.alt} />
              <div className="content">
                <h3>{item.title}</h3>
                <p>{item.content}</p>
                <Link
                  className="button"
                  to={`/services/${item.linkToPage?.slug}`}
                >
                  Read more
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Allinone;
